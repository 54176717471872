import CloseIcon from '../../assets/cross.svg';
import HamburgerIcon from '../../assets/hamburger.svg';

class Navbar {
  constructor() {
    const closeIcon = document.querySelector('#close-navbar');

    const hamburgerIcon = document.querySelector('#hamburger');
    const navbar = document.querySelector('#navbar ul');

    closeIcon.src = CloseIcon;
    hamburgerIcon.src = HamburgerIcon;

    const home = document.querySelector('#home-link');
    home.addEventListener('click', (e) => {
      e.preventDefault();
      const url = window.location.toString().split('/pages')[0];
      console.log(url);

      window.location = url + '/pages/home';
    });

    closeIcon.addEventListener('click', () => {
      navbar.style.transform = 'translateX(300px)';
      navbar.style.display = 'flex';
    });

    hamburgerIcon.addEventListener('click', () => {
      navbar.style.transform = 'translateX(0)';
      navbar.style.display = 'flex';
    });
  }
}

export default Navbar;
