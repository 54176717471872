class Scroller {
  constructor() {
    const about = document.querySelector('#about-link');
    const contact = document.querySelector('#contact-link');
    const products = document.querySelector('#products-link');
    const customers = document.querySelector('#customers-link');
    const certifications = document.querySelector('#certifications-link');
    const ourTeam = document.querySelector('#our-team-link');
    const experiences = document.querySelector('#experience-link');

    about.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#about-us');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });

    contact.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#contact-us');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });

    customers.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#customers');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });

    products.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#services');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });

    certifications.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#certifications');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });

    ourTeam.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#employees');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });

    contact.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#contact-us');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });

    experiences.addEventListener('click', (e) => {
      e.preventDefault();
      const item = document.querySelector('#experiences');
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
        });
    });
  }
}

export default Scroller;
